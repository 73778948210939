const selectScrollInit = () => {
  const body = document.querySelector('body')

  if (body.classList.contains('page-guideline') || body.classList.contains('page-booth')) {
    const selectGuide = document.querySelector('[name="selectGuide"]')
    selectGuide.addEventListener('change', (e) => {
      e.preventDefault()

      const targetId = e.target.value
      const targetElement = document.querySelector(targetId)
      const rectTop = targetElement.getBoundingClientRect().top
      const offsetTop = window.pageYOffset
      const top = rectTop + offsetTop - 100
      window.scrollTo({
        top,
        behavior: 'smooth',
      })
    })

    const isMobileWindowSize = window.matchMedia('(min-width: 768px)')

    const sideNavFixed = () => {
      if (isMobileWindowSize.matches) {
        const { scrollY, pageYOffset } = window
        const sideNav = document.querySelector('.guideMenu')
        const sideNavHeight = sideNav.clientHeight
        const guide = document.querySelector('.guide-wrapper')
        const guideTop = guide.getBoundingClientRect().top + pageYOffset - 100
        const footerTop = document.querySelector('.top__banner').getBoundingClientRect().top + scrollY - sideNavHeight - 100

        if (scrollY > footerTop) {
          sideNav.classList.remove('is-fixed')
          sideNav.classList.add('is-bottom')
        } else if (scrollY > guideTop) {
          sideNav.classList.add('is-fixed')
          sideNav.classList.remove('is-bottom')
        } else {
          sideNav.classList.remove('is-fixed')
          sideNav.classList.remove('is-bottom')
        }
      }
    }
    window.addEventListener('load', sideNavFixed)
    window.addEventListener('scroll', sideNavFixed)
    window.addEventListener('resize', sideNavFixed)
  }
}
export { selectScrollInit }
