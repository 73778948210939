import { ua } from './modules/ua'
import { selectScrollInit } from './modules/selectScroll'
import lottie from 'lottie-web'
import { realTimeTable } from './modules/realtime'
import { goodsInit } from './modules/goods'
import { timetableInit } from './modules/timetable'

const klass = {
  active: 'is-active',
  fixed: 'is-fixed',
  view: 'is-view',
  selected: 'is-selected',
  hidden: 'is-hidden',
  eventNone: 'is-eventNone',
  landscape: 'is-landscape',
  current: 'is-current',
  large: 'is-large',
  nav: 'view-nav',
}

class App {
  constructor() {
    this.body = document.querySelector('body')
    this.init()
  }

  init() {
    this.initPages()
  }

  initPages() {
    this.body.classList.add(`is-${ua.browser()}`)
    this.body.classList.add(`is-${ua.os()}`)

    setTimeout(() => {
      this.body.classList.add(`is-loaded`)
    }, 500)

    // ナビゲーション開閉
    document.querySelector('.nav-trigger').addEventListener('click', () => {
      document.querySelector('body').classList.toggle(klass.nav)
    })

    // ナビゲーションを押下時に閉じる
    Array.from(document.querySelectorAll('.gNav a')).forEach((trigger) => {
      trigger.addEventListener('click', () => {
        document.querySelector('body').classList.toggle(klass.nav)
      })
    })

    selectScrollInit()
    timetableInit()

    goodsInit()

    if (document.querySelector('#top__timeLine')) {
      realTimeTable()
    }

    if (this.body.classList.contains('page-index')) {
      window.addEventListener('scroll', () => {
        if (window.scrollY > 20) {
          this.body.classList.add('is-scrolled')
        } else {
          this.body.classList.remove('is-scrolled')
        }
      })

      const mediaQuery = matchMedia('(min-width: 768px)')
      if (mediaQuery.matches) {
        const lottieScrollAnimation = lottie.loadAnimation({
          container: document.querySelector('.top__scroll-bar'),
          renderer: 'svg',
          loop: true,
          autoplay: true,
          path: '/wp-content/themes/sc24_1/assets/json/scroll-right-animation.json',
        })
        lottieScrollAnimation.play()
      }
    }
  }
}

document.addEventListener('DOMContentLoaded', () => {
  window.sc = new App()
})

// window.addEventListener('load', () => {
// })
