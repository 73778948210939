const timetableInit = () => {
  if (document.querySelector('body').classList.contains('page-timetable')) {
    // const ttTab = document.querySelectorAll('.timeTableDay')
    // const ttImg = document.querySelectorAll('.timeTableImg')
    // if (ttTab.length > 0) {
    //   Array.from(ttTab).forEach((trigger, n, t) => {
    //     trigger.addEventListener('click', (e) => {
    //       e.preventDefault()
    //       Array.from(ttTab).forEach((tab) => tab.classList.remove(klass.selected))
    //       Array.from(ttImg).forEach((img) => img.classList.remove(klass.selected))
    //       trigger.classList.add(klass.selected)
    //       document.querySelector(trigger.getAttribute('data-id')).classList.add(klass.selected)
    //     })
    //   })
    // }

    const largeButton = document.querySelector('.timeTableLargeButton button')
    largeButton.addEventListener('click', (e) => {
      e.preventDefault()
      const _this = largeButton
      _this.classList.toggle('is-large')

      document.querySelector('.timeTableArea').classList.toggle('is-large')
      if (_this.classList.contains('is-large')) {
        _this.innerHTML = 'タイムテーブルを元に戻す'
      } else {
        _this.innerHTML = 'タイムテーブルを大きくする'
      }
    })
  }
}
export { timetableInit }
