/**
 * 現在時刻の取得
 * @param full / true or false
 * @returns {string} 日時データ Y-m-dT00:00:00.000Z / Y-m-d 00:00:00
 */
const getNowDate = () => {
  const dateFunc = new Date()
  let nowyFunc = dateFunc.getFullYear()
  let nowmFunc = dateFunc.getMonth() + 1
  let nowdFunc = dateFunc.getDate()
  let nowhFunc = dateFunc.getHours()
  let nowmiFunc = dateFunc.getMinutes()
  let nowseFunc = dateFunc.getSeconds()
  nowmFunc = ('0' + nowmFunc).slice(-2)
  nowdFunc = ('0' + nowdFunc).slice(-2)
  nowhFunc = ('0' + nowhFunc).slice(-2)
  nowmiFunc = ('0' + nowmiFunc).slice(-2)
  nowseFunc = ('0' + nowseFunc).slice(-2)
  return {
    year: nowyFunc,
    month: nowmFunc,
    day: nowdFunc,
    hour: nowhFunc,
    minutes: nowmiFunc,
    second: nowseFunc,
  }
}

const realTimeTable = () => {
  const timeLineInit = {
    windowHalf: document.querySelector('.timeLineViewPort').clientWidth / 2,
    viewPort: document.querySelector('.timeLineViewPort'),
    timeLine: document.querySelector('.timeLineTimeLine'),
    bands: document.querySelectorAll('.timeLineBands'),
    marker: document.querySelector('.timeLineMarker'),
    p: 225,
    w: 450,
    days: 2,
    today: Number(getNowDate().year + getNowDate().month + getNowDate().day),
    hour: getNowDate().hour,
    minutes: getNowDate().minutes,
  }
  const day1 = 20240611
  const day2 = 20240616
  const day2Diff = timeLineInit.today == day2 ? timeLineInit.w * 24 : 0

  let nowPosi = timeLineInit.hour * timeLineInit.w + (timeLineInit.minutes / 60) * timeLineInit.w + timeLineInit.p
  nowPosi += day2Diff

  if (timeLineInit.today >= day1 && timeLineInit.today < day2) {
    timeLineInit.marker.style.left = `${nowPosi}px`
    // timeLineInit.timeLine.style.width = `${timeLineInit.w * timeLineInit.days * 24}px`
    timeLineInit.timeLine.style.width = `${timeLineInit.w * 24}px`

    timeLineInit.viewPort.scrollLeft = `${nowPosi - timeLineInit.windowHalf}`

    Array.from(timeLineInit.bands).forEach((band) => {
      const bandData = {
        day: Number(band.getAttribute('data-d')),
        h: band.getAttribute('data-h'),
        m: band.getAttribute('data-m'),
        stage: Number(band.getAttribute('data-stage')),
        play: band.getAttribute('data-play'),
        left: timeLineInit.p,
        class: null,
      }

      if (bandData.day === 5) {
        bandData.left += timeLineInit.w * 24
      }
      if (bandData.stage === 0) {
        bandData.class = 'satan'
      } else if (bandData.stage === 1) {
        bandData.class = 'evil'
      } else if (bandData.stage === 2) {
        bandData.class = 'hell'
      } else if (bandData.stage === 3) {
        bandData.class = 'booth'
      } else if (bandData.stage === 4) {
        bandData.class = 'skate'
      } else if (bandData.stage === 5) {
        bandData.class = 'open'
      }

      bandData.left += bandData.h * timeLineInit.w
      bandData.left += bandData.m * (timeLineInit.w / 60)

      band.style.left = `${bandData.left}px`
      band.style.width = `${bandData.play * (timeLineInit.w / 60)}px`
      band.classList.add(bandData.class)
    })

    setInterval(() => {
      timeLineInit.today = Number(getNowDate().year + getNowDate().month + getNowDate().day)
      timeLineInit.hour = getNowDate().hour
      timeLineInit.minutes = getNowDate().minutes

      let nowPosi = timeLineInit.hour * timeLineInit.w + (timeLineInit.minutes / 60) * timeLineInit.w + timeLineInit.p
      nowPosi += day2Diff

      timeLineInit.marker.style.left = `${nowPosi}px`
    }, 30000)
  } else {
    // document.querySelector('#tpo__timeLine').remove()
  }
}

export { realTimeTable }
