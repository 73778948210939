const goodsInit = () => {
  if (document.querySelector('body').classList.contains('page-goods')) {
    const largeButton = document.querySelector('#goodsList .biggerButton')
    largeButton.addEventListener('click', (e) => {
      e.preventDefault()
      document.querySelector('#goodsList').classList.toggle('is-active')
    })
  }
}

export { goodsInit }
